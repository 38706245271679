@import url("https://use.typekit.net/utu6ktv.css");

@keyframes loading {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
@keyframes come-up {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* keyframes for background position */
@keyframes bg-pos {
  0% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 10%;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400;
}
body {
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-size: 1.2em;
}
button {
  cursor: pointer;
}
main {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #131129;
}
button.main-button {
  padding: 0.8em 1.5rem;
  background-color: #f01778;
  color: white;
  font-weight: 700;
  border-radius: 0.5em;
  cursor: pointer;
  transition: all 0.2s;
}
label.main-button {
  padding: 0.8em 1.5rem;
  background-color: #f01778;
  color: white;
  font-weight: 700;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.2s;
}
button.main-button:hover {
  background-color: #cf1167;
}
.main-screen {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #131129;
  text-align: center;
  position: relative;
}
.main-screen > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 3em;
  gap: 3em;
}
.active {
  color: white !important;
  position: relative;
}
.before::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 0.35em;
  background-color: #bb46e3;
  border-radius: 25%;
  transform: translate(-10%, -50%);
}
.gray-screen {
  background-color: rgba(47, 47, 47, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  padding: 6em;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
  /* blur */
  backdrop-filter: blur(5px);
}
.dropdown {
  background-color: transparent;
  color: white;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0.25em;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: all 0.25s ease;
}
.dropdown div {
  border-radius: 0.25em;
}
.dropdown div:hover {
  background-color: #f0177951;
  transition: all 0.25s ease;
}
.dropdown div.active {
  background-color: #570a2d51;
  transition: all 0.25s ease;
}

.dropdown.active {
  opacity: 1;
  top: 100%;
  visibility: visible;
  transition: all 0.2s ease-in-out;
}

.schadule div {
  color: white;
  background-color: #1d1933;
  border-radius: 0.4em;
}
.schadule button {
  background-color: transparent;
  color: white;
}
.schadule span {
  color: gray;
}
.schadule svg {
  color: #7e9afc;
}
.schadule button.Mui-disabled {
  background-color: transparent;
  color: darkgray;
}

.schadule button.Mui-selected {
  color: #42b64d !important;
  background-color: transparent !important;
  border: 2px solid #42b64d;
}
.time-box {
  width: 20%;
  margin: 3%;
  aspect-ratio: 1/1;
  background-color: #1d1933;
  padding: 0.5em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
}
.responsive-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  gap: 2em;
}
.responsive-row > div {
  width: 350px;
}
.loading {
  height: 2em;
  aspect-ratio: 1/1;
  border-top: 5px solid rgb(196, 196, 196);
  border-bottom: 5px solid rgb(196, 196, 196);
  border-right: 5px solid rgb(122, 122, 122);
  border-left: 5px solid rgb(122, 122, 122);
  border-radius: 50%;
  margin-left: 1em;
  animation: loading 1s linear infinite;
}
.progress-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 47, 47, 0.7);
  backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 3em;
  animation: come-up 0.5s ease-in-out;
  overflow: auto;
}
.progress-screen img {
  width: 50%;
  margin-top: 10em;
}

.progress {
  background-image: linear-gradient(
    to right,
    #f01778,
    #bb46e3,
    #f01778,
    #bb46e3,
    #f01778,
    #bb46e3,
    #f01778,
    #bb46e3
  );
  background-size: 500% 500%;
  padding: 1em 0.2em;
  border-radius: 0.5em;
  animation: bg-pos 3s ease-in-out infinite alternate;
}
.progress.state-done {
  border: 4px solid green;
  animation: none;
}
.stages {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  gap: 1em;
  position: relative;
}
.stage {
  border-top: 2px solid #42b64d;
  border-right: 2px solid #42b64d;
  width: 1em;
  height: 1em;
  transform: rotate(45deg);
  position: fixed;
  top: 1em;
  left: 28.5%;
  translate: -50% -50%;
  transition: top 0.5s ease-in-out;
}
.stages div {
  width: 35%;
}

.loading-btn div {
  color: #f01778;
}

@media (max-width: 600px) {
  .progress-screen img {
    width: 100%;
    margin-top: 10em;
  }
  .stages div {
    width: 75%;
  }
  .stages span {
    left: 7%;
  }
  .main-screen {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .main-screen > div {
    width: 100%;
    margin: 1em 0;
    padding: 0em 1em;
  }
  .responsive-row > div {
    width: 100%;
  }
  .responsive-row div label {
    color: white;
  }
  .css-xelq0e-MuiPickerStaticWrapper-content {
    min-width: 100% !important;
  }
}
